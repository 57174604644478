import moment from "moment";

export default {
    formatarDataLocal(value) {
        if (value) {
            return moment.utc(String(value)).local().format("DD/MM/YYYY");
        }
        return "";
    },
    formatarDataLocalsemUtc(value) {
        if (value) {
            return moment(String(value)).local().format("DD/MM/YYYY");
        }
        return "";
    },
    formatarDataHoraLocal(value) {
        if (value) {
            return moment.utc(String(value)).local().format("DD/MM/YYYY HH:mm");
        }
        return "";
    }
}
