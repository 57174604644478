<template>
  <div class="minha-conta">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>

          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div class="bg-gradient">
      <div class="container text-center pt-3">
        <h3>Cobranças</h3>
        <div class="h-line" style="width:142px;"></div>
      </div>
    </div>

    <div class="container mt-5 pb-5">
      <div>
        <div class="row mb-4">
          <div class="col-md-4 mb-2">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Digite o ID Assinatura IUGU"
                v-model="idAssinaturaIugu"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-success"
                  type="button"
                  @click="buscarCobrancas()"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 order-lg-2">
          <div v-if="!loading">
            <table class="table text-center" v-if="cobrancas.length > 0">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>CPF</th>
                  <th>Email</th>
                  <th>Data</th>
                  <th>Valor</th>
                  <th>Marca</th>
                  <th>Modelo</th>
                  <th>Status Cobrança</th>
                  <th>Status Seguro</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in cobrancas" :key="index">
                  <td>
                    {{ item.nome }}
                  </td>
                  <td>
                    {{ item.cpf }}
                  </td>
                  <td>
                    {{ item.email }}
                  </td>
                  <td>
                    {{
                      formatacaoData.formatarDataHoraLocal(item.dataCobranca)
                    }}
                  </td>
                  <td>{{ formatacaoMoeda(item.valorCobranca) }}</td>
                  <td>
                    {{ item.marcaBike }}
                  </td>
                  <td>
                    {{ item.modeloBike }}
                  </td>
                  <td>
                    {{ item.statusCobranca }}
                  </td>
                  <td>
                    <SituacaoSeguro :situacao="item.statusSeguro" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-else class="text-center">
            <lottie-player
              src="/anim/mountain_bike.json"
              background="transparent"
              speed="1.7"
              style="width: 72px; height: 72px; margin:0 auto;"
              autoplay
              loop
              class="mt-3"
            ></lottie-player>
            <div>Carregando, por favor aguarde...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../../components/Admin/SideNav";
import SituacaoSeguro from "../../components/Admin/SituacaoSeguro";
import config from "../../config";
import auth from "../../auth";
import Swal from "sweetalert2";
import axios from "axios";
import captureError from "../../helpers/captureError";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import formatacaoData from "../../helpers/formatacaoData";

export default {
  components: {
    SideNav,
    SituacaoSeguro,
  },
  data() {
    return {
      config,
      loading: false,
      cobrancas: [],
      idUsuarioAdm: undefined,
      formatacaoMoeda,
      formatacaoData,
      idAssinaturaIugu: undefined,
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (userInfo && userInfo.cargo) {
      this.idUsuarioAdm = userInfo.id;
    } else {
      this.$router.replace("/login");
    }
  },
  methods: {
    async buscarCobrancas() {
      if (!this.idAssinaturaIugu) return;

      this.loading = true;
      try {
        var response = await axios.get(
          `${config.API_URLV2}/cobrancaSeguro/obterCobrancaPeloId?idAssinaturaIugu=${this.idAssinaturaIugu}`
        );

        if (response.data.length === 0) {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            html: `Não foi encontrada nenhuma cobrança para esse ID ASSINATURA IUGU`,
          });
        }

        this.cobrancas = response.data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível consultar as cobranças. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>
<style scoped>
.minha-conta {
  height: 100%;
  /* background-color: #f7f7f7; */
}

.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.14609593837535018) 0%,
    rgba(255, 255, 255, 0) 70%
  );
}

.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.not-collapsed .when-closed,
.collapsed .when-open {
  display: none;
}
</style>
