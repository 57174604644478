<template>
  <div>
    <span
      v-if="situacao == config.SITUACAO_APROVADA"
      class="badge badge-success"
      >Aprovada</span
    >
    <span v-if="situacao == config.SITUACAO_ENVIADA" class="badge badge-primary"
      >Enviada</span
    >
    <span v-if="situacao == config.SITUACAO_ENCERRADO" class="badge badge-secondary"
      >Encerrado</span
    >
    <span
      v-if="situacao == config.SITUACAO_PENDENTE"
      class="badge badge-warning"
      >Pendente</span
    >
    <span
      v-if="situacao == config.SITUACAO_REPROVADA"
      class="badge badge-danger"
      >Reprovada</span
    >
    <span
      v-if="situacao == config.SITUACAO_CANCELADA"
      class="badge badge-secondary"
      >Cancelada</span
    >
  </div>
</template>

<script>
import config from "../../config";
export default {
  props: ["situacao"],
  components: {},
  data() {
    return { config };
  },
  created() {},
  methods: {},
};
</script>
